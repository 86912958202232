<template>
  <div class="page page-modifyauthinfo">
    <div class="page-content">
      <el-form :model="ruleForm1" :rules="rules1" ref="ruleForm1" label-width="140px">
        <el-form-item label="姓名" prop="realName">
          <el-input v-model="ruleForm1.realName" placeholder="请输入真实姓名"></el-input>
        </el-form-item>
        <el-form-item label="身份证号" prop="idNumber">
          <el-input v-model="ruleForm1.idNumber" placeholder="请输入身份证号码"></el-input>
        </el-form-item>
        <el-form-item label="手机号" prop="phone">
          <el-input v-model="ruleForm1.phone" placeholder="请输入手机号码"></el-input>
        </el-form-item>
        <el-form-item label="短信验证码" prop="code">
          <el-row type="flex" :gutter="4">
            <el-col :span="17"><el-input v-model="ruleForm1.code" placeholder="请输入短信验证码"></el-input></el-col>
            <el-col :span="7">
              <el-button type="primary" style="width:100%" @click="getCode" :disabled="codeCount>0">
                {{codeCount > 0 ? `${codeCount}秒后可重试`: '获取验证码'}}
              </el-button>
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item label="身份证扫描件" prop="idPhoto" class="idphoto-wrapper">
          <el-row type="flex" :gutter="20">
            <el-col :span="12">
              <imageUpload
                :options="imageUploadOptions_1"
                @change="imageUploadFrontChange"
              ></imageUpload>
            </el-col>
            <el-col :span="12">
              <imageUpload
                :options="imageUploadOptions_2"
                @change="imageUploadBackChange"
              ></imageUpload>
            </el-col>
          </el-row>
          <el-input v-model="ruleForm1.idPhoto"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button class="submit-btn" type="primary" @click="submitForm1">提交</el-button>
        </el-form-item>
      </el-form>
      <el-form :model="ruleForm2" :rules="rules2" ref="ruleForm2" label-width="140px">
        <el-form-item label="企业/单位名称" prop="name">
          <el-input v-model="ruleForm2.name" placeholder="填写时请与营业执照上的企业注册名称相同"></el-input>
        </el-form-item>
        <el-form-item label="统一社会信用代码" prop="socialCode">
          <el-input v-model="ruleForm2.socialCode" placeholder="请务必与营业执照上的注册号或统一社会信用代码号一致"></el-input>
        </el-form-item>
        <el-form-item label="营业执照扫描件" prop="businessLicense" class="idphoto-wrapper">
          <el-row type="flex" :gutter="20">
            <el-col :span="12">
              <imageUpload
                :options="imageUploadOptions_3"
                @change="imageUploadLicenseChange"
              ></imageUpload>
            </el-col>
            <el-col :span="12">
            </el-col>
          </el-row>
          <el-input v-model="ruleForm2.businessLicense"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button class="submit-btn" type="primary" @click="submitForm2">提交</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import {mapState, mapMutations, mapActions} from 'vuex'
import imageUpload from '../../components/image-upload.vue'
import publicUrl from '../../assets/js/interface'
import utils from '../../assets/js/ossUtil'

export default {
  name: 'page-modifyauthinfo',
  components: {
    imageUpload
  },
  data () {
    return {
      loadingCount: 0,
      codeCount: 0,
      codeTimer: null,
      ruleForm1: {
        code: '',
        idNumber: '',
        idPhoto: '',
        idPhotoContrary: '',
        idPhotoFront: '',
        phone: '',
        realName: ''
      },
      rules1: {
        realName: [
          { required: true, message: '请输入真实姓名', trigger: 'blur' },
          { min: 1, max: 60, message: '长度在 1 到 60 个字符', trigger: 'blur' }
        ],
        idNumber: [
          { required: true, message: '请输入身份证号码', trigger: 'blur' }
        ],
        phone: [
          { required: true, message: '请输入手机号码', trigger: 'blur' }
        ],
        code: [
          { required: true, message: '请输入短信验证码', trigger: 'blur' }
        ],
        idPhoto: [
          { required: true, message: '请上传身份证扫描件', trigger: 'blur' }
        ]
      },
      ruleForm2: {
        name: '',
        socialCode: '',
        businessLicense: ''
      },
      rules2: {
        name: [
          { required: true, message: '请输入公司名称', trigger: 'blur' }
        ],
        socialCode: [
          { required: true, message: '请输入统一社会信用代码', trigger: 'blur' }
        ],
        businessLicense: [
          { required: true, message: '请上传营业执照', trigger: 'blur' }
        ]
      }
    }
  },
  computed: {
    ...mapState(['userInfo', 'resourceInfoDict']),
    idPhoto () {
      let result = (this.ruleForm1.idPhotoContrary && this.ruleForm1.idPhotoFront) ? 'true' : ''
      this.$set(this.ruleForm1, 'idPhoto', result)
      return result
    },
    imageUploadOptions_1 () {
      let result = {
        photoType: 'idcard_front',
        photoLabel: '身份证人像面'
      }
      if (this.resourceInfoDict[this.ruleForm1.idPhotoContrary]) {
        result.photoSrc = this.resourceInfoDict[this.ruleForm1.idPhotoContrary]
      }
      return result
    },
    imageUploadOptions_2 () {
      let result = {
        photoType: 'idcard_back',
        photoLabel: '身份证国徽面'
      }
      if (this.resourceInfoDict[this.ruleForm1.idPhotoFront]) {
        result.photoSrc = this.resourceInfoDict[this.ruleForm1.idPhotoFront]
      }
      return result
    },
    imageUploadOptions_3 () {
      let result = {
        photoType: 'license',
        photoLabel: '营业执照'
      }
      if (this.resourceInfoDict[this.ruleForm2.businessLicense]) {
        result.photoSrc = this.resourceInfoDict[this.ruleForm2.businessLicense]
      }
      return result
    }
  },
  created () {
    this.companyManagerInfo()
  },
  methods: {
    ...mapMutations(['setManagerInfo', 'setCompanyInfo']),
    ...mapActions(['resourceInfoGetter', 'companyManagerStatus']),
    companyManagerInfo () {
      this.loadingCount++
      this.$ajax.get(publicUrl.companyManagerInfo, {
        passportId: this.userInfo.account
      }).then(res => {
        if (res.data.code === 0) {
          let _data = res.data.data || {}
          let _company = _data.company || {}
          let _manager = _data.manager || {}
          this.ruleForm1 = {
            code: this.ruleForm1.code,
            idNumber: _manager.idNumber,
            idPhotoContrary: _manager.idPhotoContrary,
            idPhotoFront: _manager.idPhotoFront,
            phone: _manager.phone,
            realName: _manager.name
          }
          this.ruleForm2 = {
            businessLicense: _company.businessLicense,
            socialCode: _company.socialCode,
            name: _company.name
          }
          this.setManagerInfo(_data.manager)
          this.setCompanyInfo(_data.company)
          let fileNames = [
            _manager.idPhotoContrary,
            _manager.idPhotoFront,
            _company.businessLicense
          ].filter(v => !!v)
          this.resourceInfoGetter({vm: this.$root, fileNames})
        } else {
          this.$message.error(res.data.msg)
        }
      }).catch(err => {
        this.$ajaxError(err)
      }).finally(() => {
        this.loadingCount--
      })
    },
    imageUploadFrontChange (file) {
      this.loadingCount++
      utils.upload(file, {}, false).then((res) => {
        if (res === 'cancel') return
        this.ruleForm1.idPhotoContrary = res.resourceKey
      }).catch(err => {
        this.$message.error('图片上传失败')
        this.$ajaxError(err)
      }).finally(() => {
        this.loadingCount--
      })
    },
    imageUploadBackChange (file) {
      this.loadingCount++
      utils.upload(file, {}, false).then((res) => {
        if (res === 'cancel') return
        this.ruleForm1.idPhotoFront = res.resourceKey
      }).catch(err => {
        this.$message.error('图片上传失败')
        this.$ajaxError(err)
      }).finally(() => {
        this.loadingCount--
      })
    },
    imageUploadLicenseChange (file) {
      this.loadingCount++
      utils.upload(file, {}, false).then((res) => {
        if (res === 'cancel') return
        this.ruleForm2.businessLicense = res.resourceKey
      }).catch(err => {
        this.$message.error('图片上传失败')
        this.$ajaxError(err)
      }).finally(() => {
        this.loadingCount--
      })
    },
    getCode () {
      if (!this.ruleForm1.phone) {
        this.$message.warning('请输入手机号码')
        return
      }
      this.loadingCount++
      this.codeCount = 30
      this.codeTimer = setInterval(() => {
        this.codeCount--
        if (this.codeCount <= 0) {
          clearInterval(this.codeTimer)
          this.codeCount = 0
          this.codeTimer = null
        }
      }, 1000)
      this.$ajaxJson.post(publicUrl.getChangeManagerCode, {
        phone: this.ruleForm1.phone
      }).then((res) => {
        if (res.data.code === 0) {
          this.$message.success(res.data.msg || '验证码已发出')
        } else {
          this.$message.error(res.data.msg)
        }
      }).catch(err => {
        this.$message.error('验证码获取失败')
        this.$ajaxError(err)
      }).finally(() => {
        this.loadingCount--
      })
    },
    submitForm1 () {
      // 为了解决computed不给力的问题
      this.ruleForm1.idPhoto = (this.ruleForm1.idPhotoContrary && this.ruleForm1.idPhotoFront) ? 'true' : ''
      this.$refs.ruleForm1.validate((valid) => {
        if (valid) {
          this.loadingCount++
          this.$ajaxJson.post(publicUrl.companyManager, {
            ...this.ruleForm1
          }).then(res => {
            if (res.data.code === 0) {
              this.$message.success(res.data.msg)
            } else {
              this.$message.error(res.data.msg)
            }
          }).catch(err => {
            this.$ajaxError(err)
          }).finally(() => {
            this.loadingCount--
          })
        } else {
          return false
        }
      })
    },
    submitForm2 () {
      this.$refs.ruleForm2.validate((valid) => {
        if (valid) {
          this.loadingCount++
          this.$ajaxJson.post(publicUrl.companyCertification, {
            ...this.ruleForm2
          }).then(res => {
            if (res.data.code === 0) {
              this.$message.success(res.data.msg)
              this.loadingCount++
              this.companyManagerStatus({
                vm: this.$root,
                ElMessage: this.$message
              }).finally(() => {
                this.loadingCount--
              })
            } else {
              this.$message.error(res.data.msg)
            }
          }).catch(err => {
            this.$ajaxError(err)
          }).finally(() => {
            this.loadingCount--
          })
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.page-content {
  width: 600px;
  margin: 0 auto;
  padding: 40px 0;
}
.idphoto-wrapper {
  height: 140px;
  .el-row, .el-col {
    height: 100%;
  }
  .el-input {
    display: none;
  }
}
.submit-btn {
  width: 100%;
}
</style>
